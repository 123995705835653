import './admin.css'
import { Switch , Route, useParams, Link, Redirect } from 'react-router-dom'
import React, {useState, useEffect} from 'react'
import logo1 from '../../../src/images/logo.svg'
import { useSelector, useDispatch } from 'react-redux'
import { setAdmin, setCategory } from '../store/counterSlice'
import Cookies from 'js-cookie'
import logo from '../../../src/images/logoTypo.svg'
import { __src, __url__, __dev__ } from '../../config'

function Admin (){
    useEffect(() => {
        document.title = 'Solenis | Administration';
        console.log("admin")
      }, []);
    const [cookie, setCookie] = useState('')
    useEffect(()=>{
        if((cookie!=='' && cookie!=undefined) || __dev__ ){
            setLogin(true)
        }
        setCookie(Cookies.get('Auth'))

        console.log(cookie)
    },[cookie])
    const admin = useSelector(state =>state.solenis.admin)
    const catContent = useSelector(state => state.solenis.category)
    const [login, setLogin]=useState(false)
    return (
        <div className="container admin">
            <Link to='/'>
                <div className='adminlogo'>
                   <img src={logo1} height='50px'/>
                   <img src={logo} height='50px' style={{marginLeft:"15px"}}/>

                </div>
            </Link>
            <Switch>
            {!login && <Redirect from='/admin/:page' to='/admin'/>}


                <Route exact path='/admin'>
                    {!login && <Login cookie={[cookie, setCookie]}/>}
                    {login && <div className="menu">
                        
                        <Link to='/admin/general' className='menu-item'>Général</Link>
                        <Link to='/admin/accueil' className='menu-item'>Accueil</Link>
                        <Link to='/admin/expertise' className='menu-item'>Expertise</Link>
                        <Link to='/admin/carriere' className='menu-item'>Carriere</Link>
                        <Link to='/admin/avis' className='menu-item'>Avis Clients</Link>
                        <Link to='/admin/audit' className='menu-item'>Audit</Link>
                        <Link to='/admin/mentions-legales' className='menu-item'>Mention</Link>
                        <Link to='/admin/politiques-de-confidentialité-des-données' className='menu-item'>Politiques</Link>

                    </div>}

                </Route>
                <Route exact path='/admin/menu'><Login/></Route>

                <Route exact path='/admin/:page' children={<Edit/>}/>
                <Route path='/admin/:page/:category' children={<EditListIem item={catContent}/>}/>

            </Switch>

        </div>
    )
}

function Edit(){
    const [data, setData] = useState('')
    let {page, category} = useParams();
    const [isList, setIsList]= useState(false)
    const dispatch = useDispatch()
    console.log()
    useEffect(()=>{
        fetch( __src + `/json/${page}.json`,{

            headers:{
                'Cache-Control' : 'no-cache, no-store, must-revalidate',
                'Pragma' : 'no-cache',
                'Expire' : "0",
            }
        })
        .then(function(response){
            console.log(response)
            response.json().then(function(json){
                console.log(json)
                if(!Array.isArray(json)){
                    setIsList(true)
                    console.log(true)
                }else{
                    setIsList(false)
                }
                setData(json)
                dispatch(setAdmin(json.complexe))
                console.log(json)
            })
        })


    },[])

    const filename = `${page}.json`
    const path = `/admin/${page}`
    const clickhandler= ()=>{
        setData((prevState)=>{
            console.log(prevState)
            return [...prevState, {titre:'', contenu:''}]
        })
    }
    const addJob = (e)=>{
        if(e.keyCode == 13){
            console.log(newJob)
            setData((prevState)=>{
                return {complexe : [...prevState.complexe, {category:newJob, content:[{titre :''}]}]}
            })
            setNewJob('')
        }
    }
    const [newJob, setNewJob] = useState('')
    console.log(__url__)
    return(
        <>
        <form className='editcontainer' action={__url__} method='post' encType='multipart/form-data'>
                    <input type='text' value={filename} name='filename' style={{display:'none'}}/>

                    <Link to='/admin' className='backlink'>Menu &#8594;</Link>
                    <Link to={path} className='backlink2'>{page} &#8594;</Link>

                    {data.length!==0 && Array.isArray(data) && data.map((item)=>{
                        return <EditItem item={item}/>
                    })}
                    {data.length!==0 && !Array.isArray(data) && data.complexe.map((item)=>{
                        return <EditList item={item}/>
                    })}
                    <input type="submit" value='Enregistrer les modifications' className='submit' style={(page=="mentions-legales" || page=="politiques-de-confidentialité-des-données" )?null:{display:'none'}}/>

        </form>
        <button onClick={clickhandler} style={(page=="mentions-legales" ||page=="politiques-de-confidentialité-des-données")?null:{display:'none'}}>Nouveau Contenu</button>

        </>

    )
}
function EditList({item}){
    let {page} = useParams()
    const path = `/admin/${page}/${item.category}`
    const dispatch = useDispatch()
    return(
        <Link className='menu-item' to={path} onClick={()=>{dispatch(setCategory(item.content))}}>{item.category}</Link>
    )
}
function EditListIem({item}){
    let{page, category} = useParams()
    const [data, setData]= useState(item);
    const url=`/admin/${page}`
    const filename = `${page}.json`
    const clickhandler= ()=>{
        setData((prevState)=>{
            console.log(prevState)
            return [...prevState, {titre:'', contenu:''}]
        })
    }

    const handleSubmit = (e)=>{
        e.preventDefault()
        console.log('submit')
    }
    const deletehandler = (e)=>{
        e.preventDefault()
        let data = {
            champ1: 'champ1Value',
            champ2: 'champ2Value',
            nouvelleVariable: 'nouvelleVariable'
        };
        window.location.href=`https://solenis-enr.fr/admin.php?event=delete&category=${category}&filename=${page}.json`
        // Convertir l'objet en chaîne JSON
    }
    console.log(data.length!==0 && Array.isArray(data) && page!='carriere' && page!='expertise' || category=="Titre de la page")
    return <><form className='editcontainer'  action={__url__} method='post' encType='multipart/form-data'>
                <input type='text' value={filename} name='filename' style={{display:'none'}}/>
                <input type='text' value={category} name='category' style={{display:'none'}}/>

        <Link to='/admin' className='backlink'>Menu &#8594;</Link>
        <Link to={url} className='backlink2'>{page} &#8594;</Link>
        <Link to={url} className='backlink3'>{category} &#8594;</Link>

        {data.length!==0 && Array.isArray(data) && (( category != "Installations Client" && category != "Banniere") || category=="Titre de la page") && data.map((element)=>{
            return <EditItem item={element}/>
        })}
        {category=='Installations Client' &&
            <InstalForm data={data} /> 
        }
        {category=='Banniere' &&
            <BanniereForm data={data} /> 
        }


        <input type="submit" value='Enregistrer les modifications' className='submit' style={(category != 'Installations Client' &&  category != 'Banniere')?null:{display:'none'}}/>


    </form>
    <button onClick={clickhandler} style={( category=='Titre de la page' || category=='Navigation' || category=='Installations Client' || category == "Banniere")?{display:"none"}:null}>Nouveau Contenu</button>
</>
    
}
function BanniereForm ({data}) {
    const submitHandler = (e) => {
        e.preventDefault()
        const form = document.getElementsByTagName('form')
        
        if(window.confirm("Cette action peut entraîner la suppression d'image ! Souhaitez-vous continuer ?") ) {
            form[0].submit()
        }
    }
    return (
        <>
        <img  src={`https://solenis-enr.fr/media/banner/${data}`} width="100%"/>
        <input type='file' style={{border:'none'}} name='banner' accept='image/*' />
        <input type="submit" value='Enregistrer les modifications' className='delete' onClick={submitHandler}/>

        </>

    )
}
function InstalForm ({data}) {
    const [images, setImages] = useState(data)
    const [img, setImg] = useState('')
    const changeHandler = (e)=>{

        const state = data.filter((element,index)=>{
            const checkbox = document.getElementById(`checkbox-${index}`)
            if(!checkbox.checked) {
                return element
            }
        })
        setImages(state)
    }

    const submitHandler = (e) => {
        e.preventDefault()
        const form = document.getElementsByTagName('form')
        console.log(form[0])
        if(window.confirm("Cette action peut entraîner la suppression d'images ! Souhaitez-vous continuer ?") ) {
            form[0].submit()
        }
    }
    return <>
        <input value={JSON.stringify(images)} name='Installations_Client' style={{display:'none'}}/>
        {data.length!==0 && Array.isArray(data) && data.map((element, index)=>
            <div className='adminformimg'>
                <img src={`https://solenis-enr.fr/media/install/${element}`} width='80%'/>
                <input type='checkbox' onClick={changeHandler} id={`checkbox-${index}`} active/>
            </div>
        
        )}
        <input type='file' style={{border:'none'}} name='files[]' accept='image/*' multiple='multiple'/>

        <input type="submit" value='Enregistrer les modifications' className='delete' onClick={submitHandler}/>

    
    </>
}
function EditItem({item}){
    let {category} = useParams()
    console.log('test')
    console.log(item)
    const [title, setTitle]=useState(item.titre)
    const [content, setContent]=useState(item.contenu)
    const onKeyDown = (e)=>{
        if(e.key ==='Enter' && !e.shiftKey){
            e.preventDefault()
        }

    }
    if(title=="Titre du module"){
        return (
            <div className='adminform'>
                <label>Titre du module</label>
                <input type='text' value={content} onChange={(e)=>{setContent(e.target.value)}} name={title} onKeyDown={onKeyDown}/>

            </div>
        )

    }
    if(category=='Titre de la page'){
        return (
            <div className='adminform'>
                <label>Titre de la Page</label>
                <input type='text' value={content} onChange={(e)=>{setContent(e.target.value)}} name={title} onKeyDown={onKeyDown}/>

            </div>
        )
    }
    else if(category=="Navigation"){
        return (
            <div className='adminform'>
            <label>Page {title}</label>
            <input type='text' value={content} onChange={(e)=>{setContent(e.target.value)}} name={title} onKeyDown={onKeyDown}/>

        </div>

        )
    }
    else if(category=="Installations Client") {
        
        return (
            <div className='adminformimg'>
                <img src={item} width='80%'/>
                <input type='checkbox' />
            </div>
        )
    }
    return(
        <div className='adminform'>

        <input type='text' value={title} onChange={(e)=>{setTitle(e.target.value)}} onKeyDown={onKeyDown}/>
        <textarea  value={content} onChange={(e)=>{setContent(e.target.value)}} name={title} onKeyDown={onKeyDown}/>
        </div>

    )
}

function Login(props){
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [cookie, setCookie] = props.cookie
    const getResponse= async (e)=>{
e.preventDefault()
        fetch(__src + '/login.php',{
            method: 'POST',
            mode: 'cors',
            credentials: 'same-origin',
            body: JSON.stringify({"mdp":password, "user":username}),
            headers:{
                "Content-type": "application/json"
            }
        })
            .then(function(response){
                console.log(response)
                setCookie(Cookies.get('Auth'))
            })
        setPassword('')
        setUsername('')
        setCookie(Cookies.get('Auth'))

    }
    return <form className='login' action='https://solenis-enr.fr/login.php' method='post' onSubmit={getResponse} target="dummyframe">
        <iframe name="dummyframe" id="dummyframe" style={{display: 'none'}}></iframe>
        <label>
            Nom d'utilisateur
            <input type='text' value={username} onChange={(e)=>setUsername(e.target.value)} name="user"/>
        </label>
        <label>
            Mot de passe
            <input type='text' value={password} onChange={(e)=>setPassword(e.target.value)} name="mdp"/>
        </label>
        <input type='submit' value='Se connecter'/>

    </form>
}
export default Admin;