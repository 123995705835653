import React, {useEffect, useState, useRef} from "react";
import './Customers.css';
import quote from '../../images/quote.svg'
import quote2 from '../../images/quote2.svg'
import { __src } from "../../config";

function Customers(){
    
    const [data, setData] = useState([])
    const [title, setTitle]=useState([])
    const [instal, setInstal] = useState([])
    const [mainContent, setMainContent] = useState([])
    const [banner, setBanner] = useState('')
    useEffect(() => {
        document.title = 'Solenis | Avis Clients';
      }, []);


  
    useEffect(()=>{
        fetch( __src +`/json/avis.json`,{
            headers:{
                'Cache-Control' : 'no-cache, no-store, must-revalidate',
                'Pragma' : 'no-cache',
                'Expire' : "0",
            }
        })
        .then(function(response){
            response.json().then(function(json){
                for(const object of json.complexe){
                    if(object.category=='avis'){
                        setData(object.content)
                    }
                    else if(object.category=="Titre de la page"){
                        setTitle(object.content[0].contenu)
                    }
                    else if(object.category == "Contenu principal") {
                        setMainContent(object.content)
                    }
                    else if (object.category=="Installations Client") {
                        setInstal(object.content)
                    }
                    else if(object.category=="Banniere"){
                        setBanner(object.content)
                    }


                }
            })
        })


    },[])

    const [currentIndex, setCurrentIndex] = useState(0)
    // useEffect(()=>{
    //     setInterval(() => {
    //         if(data.length>1){


    //             setCurrentIndex((currentIndex + 1)% data.length);
    
    //         }

    //     }, 5000);
    // },[currentIndex,data])
    
    return (
        <div className="customers container">
                <div className="titre" style={{backgroundImage:`url(https://solenis-enr.fr/media/banner/${banner})`}}>
                    <div className="dark" >
                        <h1>{title}</h1>
                    </div>
                </div>


                <MyList items={data} index={currentIndex}/>

                <div className="pagecontent">
                    {mainContent.length>0 && mainContent.map((item)=> {return(
                            <article>
                                <h2>{item.titre}</h2>
                                <Item2 item={item.contenu}/>
                            </article>
                        )})}


                </div>


                <div className="instal">
                    {instal.length > 0 && instal.map((element)=>
                        <img src={`./media/install/${element}`} width='100%' />
                    )}
                </div>

        </div>
    )
}

function Item2({item}){

    const paraList = item.split('\r\n')
    return (
        <p>{paraList.length > 0 && paraList.map((element)=>
            <>{element}<br/></>
        )}</p>
    )
}

function MyList ({items, index}){
    const carousel = useRef();
    const [windowWidth, setWindowWidth] = useState(0)

    useEffect(()=>{
        setWindowWidth(document.getElementById('customerlist').offsetWidth)
        carousel.current.scrollLeft = windowWidth*index
    },[index, windowWidth])




    return(
        <ul className="feedbacklist" ref={carousel} id='customerlist'>
            { items.map((item, index)=> <Item props={item}  key={index}/>)}
        </ul>
    )
}
function Item ({props}){
    return (
        <li className="feedback">
            <span className="quote"><img src={quote} width='30px'/></span>
            <p>{props.contenu}<br/><br/><strong>{props.titre}</strong></p>
            <span className="quote2"><img src={quote2} width='30px'/></span>

        </li>

    )
}
export default Customers;