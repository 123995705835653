import React, { useState, useEffect } from "react";
import './Products.css';
import { __src } from "../../config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'


function Products(){
    useEffect(() => {
        document.title = 'Solenis | Solutions';
        let header = document.getElementById('header').offsetHeight+'px'
        let footer = document.getElementById('footer').offsetHeight+'px'
        let mention = document.getElementById('product-id')
        mention.style.minHeight = `calc(100vh - ${header} - ${footer})`
    }, []);
    


    const [title, setTitle]=useState([])
    const [data, setData] = useState([])
    const [banner, setBanner] = useState('')

    useEffect(()=>{
        fetch(__src+ `/json/expertise.json`,{
            headers:{
                'Cache-Control' : 'no-cache, no-store, must-revalidate',
                'Pragma' : 'no-cache',
                'Expire' : "0",
            }
        })
        .then(function(response){
            response.json().then(function(json){
                for(const object of json.complexe){
                    if(object.category =='Titre de la page'){
                        setTitle(object.content[0].contenu)

                    }
                    if(object.category =='Contenu Principale') {
                        setData(object.content)
                    }
                    else if(object.category=="Banniere"){
                        setBanner(object.content)
                    }

                }
            })
        })


    },[])

    return (

        <div className="products container" id='product-id'>
            <div className="titre" style={{backgroundImage:`url(https://solenis-enr.fr/media/banner/${banner})`}}>
                <div className="dark" >
                    <h1>{title}</h1>
                </div>
            </div>
            <div className="pagecontent">
                    <article className="homelist">
                        <h2>{title}</h2>
                        <MyList items={data}/>
                    </article>
            </div>

        </div>
    )
}

function MyList({items}){
    const clickhandler=(e)=>{
        const bool = JSON.parse(e.target.getAttribute('aria-expanded'))
        e.target.setAttribute('aria-expanded', !bool )
    }
    return (
        <div className="itemlist">
            {items.map((item)=> <div className="item">
                <button aria-expanded='false' onClick={clickhandler}>{item.titre}<div className='btnarrow'><FontAwesomeIcon icon={faPlay} size='xs'/></div></button>
                <Item item={item.contenu}/>
            </div>)}
        </div>
    )
}

function Item({item}){
    const sibingclickhandler = (e)=>{
        const bool = JSON.parse(e.target.parentNode.children[0].getAttribute('aria-expanded'))
        e.target.parentNode.children[0].setAttribute('aria-expanded', !bool)
    }

    const paraList = item.split('\r\n')
    return (
        <div className="itemcontent" onClick={sibingclickhandler}>{paraList.length > 0 && paraList.map((element)=>
            <div>{element}<br/></div>
        )}</div>
        )
}



export default Products;