import React, {useEffect, useState, useRef} from "react";
import './Jobs.css';
import { __src } from "../../config";
import quote from '../../images/quote.svg'
import quote2 from '../../images/quote2.svg'

function Jobs(){
    useEffect(() => {
        document.title = 'Solenis | Recrutement';
        let header = document.getElementById('header').offsetHeight+'px'
        let footer = document.getElementById('footer').offsetHeight+'px'
        let mention = document.getElementById('jobs-id')
        mention.style.minHeight = `calc(100vh - ${header} - ${footer})`

    }, []);
    

    const [data, setData] = useState([])
    const [mainContent, setMainContent] = useState([])
    const [title, setTitle] = useState()
    const [userName, setUsername] = useState('')
    const [email, setEmail ] = useState('')
    const [city, setCity ] = useState('')
    const [phone, setPhone ] = useState('')
    const [cvname, setCvname ] = useState('')
    const [error, setError] = useState({})
    const [succes, setSucces] = useState('')
    const [banner, setBanner] = useState('')
    const [currentIndex, setCurrentIndex] = useState(0)


    // useEffect(()=>{
    //     setInterval(() => {
    //         if(data.length>1){
    //             setCurrentIndex((currentIndex + 1)% data.length);
    
    //         }

    //     }, 5000);
    // },[currentIndex,data])

    useEffect(()=>{

        fetch(__src+`/json/carriere.json`,{
            headers:{
                'Cache-Control' : 'no-cache, no-store, must-revalidate',
                'Pragma' : 'no-cache',
                'Expire' : "0",
            }
        })
        .then(function(response){
            response.json().then(function(json){

                for(const object of json.complexe){
                    if(object.category=='avis'){
                        setData(object.content)
                    }
                    else if(object.category=="Titre de la page"){
                        setTitle(object.content[0].contenu)
                    }
                    else if(object.category=="Banniere"){
                        setBanner(object.content)
                    }
    
                    else if(object.category == "Contenu principal") {
                        setMainContent(object.content)
                    }
                }
            })
        })


    },[])

    const addCVHandler = () => {
        const fileinput = document.getElementById('file')
        fileinput.click();
    }

    const changeHandler = (e) => {
        if(e.target.files.length > 0){
            setCvname(e.target.files[0].name)
        }
    }

    const validateForm = () => {
        let prevError = {}
        if(userName.length == 0){
            prevError.userName = 'Champ obligatoire'
        }
        if(email.length == 0){
            prevError.email = 'Champ obligatoire'
        }
        if(city.length == 0){
            prevError.city = 'Champ obligatoire'
        }
        if(phone.length == 0){
            prevError.phone = 'Champ obligatoire'
        }
        if(cvname.length == 0 ){
            prevError.cvname = 'Veuillez ajouter un CV'
        }
        if(Object.keys(prevError).length ===0){
            setError({})

            return true
        }
        setError(prevError)
        return false


    }

    const submitHandler = (e) => {
        e.preventDefault()
        if(validateForm()) {
            document.getElementById('jobs-form').submit()
            setCity('')
            setCvname('')
            setEmail('')
            setUsername('')
            setPhone('')
            setSucces('Votre demande a été envoyé avec succès !')
        }
    }

    return (
            <div className="jobs container" id="jobs-id">
                    <div className="titre" style={{backgroundImage:`url(https://solenis-enr.fr/media/banner/${banner})`}}>
                        <div className="dark" >
                            <h1>{title}</h1>
                        </div>
                     </div>
                     <MyList items={data} index={currentIndex}/>



                <div className="pagecontent">
                    {mainContent.length>0 && mainContent.map((item)=> {return(
                        <article>
                            <h2>{item.titre}</h2>
                            <Item2 item={item.contenu}/>
                        </article>
                    )})}


                    <article className="Form">
                        <form action="https://solenis-enr.fr/jobsrequest.php" method="post"  encType='multipart/form-data' id="jobs-form" >
                        <iframe name="dummyframe" id="dummyframe" style={{display: 'none'}}></iframe>
                            <div className="input_container">
                                <div className="input_group">
                                    <label>Nom Prénom <span className='error'>{error.userName}</span><input type="text" placeholder="Example : Adrien Dupont" name="name" value={userName} onChange={(e)=>{setUsername(e.target.value)}} /></label>
                                </div>

                                <div className="input_group">
                                    <label>Email <span className='error'>{error.email}</span><input type="text" placeholder="Example : adrien.dupont@gmail.com" name="email" value={email} onChange={(e)=>{setEmail(e.target.value)}}/></label>
                                </div>

                                <div className="input_group">
                                    <label>Ville <span className='error'>{error.city}</span><input type="text" placeholder="Example : Lille" value={city} name="city" onChange={(e)=>{setCity(e.target.value)}}/></label>
                                </div>

                                <div className="input_group">
                                    <label>Téléphone <span className='error'>{error.phone}</span><input type="text" placeholder="Example : 06 01 02 03 04" value={phone} name="phone" onChange={(e)=>{setPhone(e.target.value)}}/></label>
                                </div>


                                <div className="input_group">
                                </div>

                                <label>CV <span className='error'>{error.cvname}</span>
                                    <div className="twoinput">
                                        <input type="file" name="file" id="file" className="file" accept=".pdf"  onChange={changeHandler}/>
                        
                                        <div className="input_group"style={{flex:'1' }}>
                                            <input type="text" name="file-name" id="file-name"  readonly="readonly" value={cvname} style={{width:'100%'}}/>
                                        </div>
                                        <div>
                                            <input type="button" className="btn-file" value="Ajouter un CV" onClick={addCVHandler}/>
                                        </div>
                                    
                                    </div>
                                </label>
                                <div className="input_container">
                                    <div>
                                    <input type="submit" className="btn-file2" value="Envoyer" onClick={submitHandler}/>

                                    </div>
                                </div>

                            </div>
                        </form>
                        <div className="succes" style={succes.length ==0? {display:'none'}:null}>
                            {succes}
                        </div>

                    </article>
                </div>

            </div>

    )
}

function Item2({item}){

    const paraList = item.split('\r\n')
    return (
        <p>{paraList.length > 0 && paraList.map((element)=>
            <>{element}<br/></>
        )}</p>
    )
}

function MyList ({items, index}){
    const carousel = useRef();
    const [windowWidth, setWindowWidth] = useState(0)

    useEffect(()=>{
        setWindowWidth(document.getElementById('customerlist').offsetWidth)
        carousel.current.scrollLeft = windowWidth*index
    },[index, windowWidth])




    return(
        <ul className="feedbacklist" ref={carousel} id='customerlist'>
            { items.map((item, index)=> <Item props={item}  key={index}/>)}
        </ul>
    )
}
function Item ({props}){
    return (
        <li className="feedback">
            <span className="quote"><img src={quote} width='30px'/></span>
            <p>{props.contenu}<br/><br/><strong>{props.titre}</strong></p>
            <span className="quote2"><img src={quote2} width='30px'/></span>

        </li>

    )
}



export default Jobs;